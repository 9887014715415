import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
const logo_url =
  "https://resumearash.blob.core.windows.net/images/profile-pic-trans.png?sp=r&st=2021-07-19T15:10:03Z&se=2023-01-02T00:10:03Z&spr=https&sv=2020-08-04&sr=b&sig=Vn64A709kcpbI8%2FImJGy%2BqFsC6GXd6t5bDpWiSGAwXI%3D";
const linkedIn_logo_url =
  "https://resumearash.blob.core.windows.net/images/linkedin.png?sp=r&st=2021-07-19T15:06:57Z&se=2023-01-02T00:06:57Z&spr=https&sv=2020-08-04&sr=b&sig=QfABMYF3Tw%2BCcO9K1kmL%2F%2FVQBY4znTYFEav0OTkW3N8%3D";
const github_logo_url =
  "https://resumearash.blob.core.windows.net/images/github-logo.png?sp=r&st=2021-07-19T15:12:36Z&se=2023-01-01T00:12:36Z&spr=https&sv=2020-08-04&sr=b&sig=DF8pq%2FUN0JtGkmpuqYUApYT5gOP1ki31sdDZABKuWVY%3D";
const resume_logo_url =
  "https://resumearash.blob.core.windows.net/images/flat-2126884_640.png?sp=r&st=2021-07-19T15:15:31Z&se=2023-01-01T00:15:31Z&spr=https&sv=2020-08-04&sr=b&sig=eeMj9yCVoqCDBuvENopgzRsWMkXHA7M%2BSFDh8LZqfcU%3D";
const resume_url =
  "https://resumearash.blob.core.windows.net/images/Arash_Abdollahzadeh_MLE.docx?sp=r&st=2021-07-21T12:49:52Z&se=2022-12-31T21:49:52Z&spr=https&sv=2020-08-04&sr=b&sig=EvUk92tAh%2Ff065dK50j9mjTO4PIq8V2gLPsGxAj8Gc0%3D";
const email_url =
  "https://resumearash.blob.core.windows.net/images/email.png?sp=r&st=2021-08-02T11:57:31Z&se=2024-04-18T19:57:31Z&spr=https&sv=2020-08-04&sr=b&sig=Hn%2B5RisqYOU%2FAXWQ7iQK8f88HdwQY%2FcHLqFfgecPZl8%3D";
const colors = {
  maroon: "#701a27", // accent
  blue: "#068D9D", // accent
  green: "#B5B967", // secondary
  platinum: "#E7E3E0", // prim
  white: "#FFFFFF", // prim
};

function Header() {
  return (
    <>
      <Container fluid style={{ backgroundColor: colors.white }}>
        <Row>
          <Col xs="5" md="6" lg="7">
            <p
              style={{
                padding: "20px 0",
                textAlign: "center",
                fontSize: "3vw",
              }}
            >
              <b> Arash Abdollahzadeh </b>
            </p>
            <p style={{ textAlign: "center", fontSize: "2vw" }}>
              Machine Learning Engineer @ Chevron
            </p>
            <div>
              <img
                src={email_url}
                style={{ borderRadius: "10%", height: "100%", width: "100%" }}
                alt="SAS prolly expired!"
              ></img>
            </div>
            <div
              style={{
                // display: "flex",
                width: "100%",
                // height: "10%",
                // paddingLeft: "",
              }}
            >
              <center>
                <span>
                  <a
                    href="https://linkedin.com/in/arash-abdollahzadeh"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={linkedIn_logo_url}
                      alt="SAS prolly expired!"
                      width="5%"
                      style={{ minWidth: "25px" }}
                    ></img>
                  </a>
                </span>
                <span>
                  <a
                    href="https://github.com/codenameAggie"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={github_logo_url}
                      alt="SAS prolly expired!"
                      width="5%"
                      style={{ minWidth: "25px", margin: "10px" }}
                    ></img>
                  </a>
                </span>

                <span>
                  <a
                    href={resume_url}
                    target="_blank"
                    height="50%"
                    rel="noreferrer"
                  >
                    <img
                      src={resume_logo_url}
                      width="5%"
                      alt="SAS prolly expired!"
                      style={{ minWidth: "25px" }}
                    ></img>
                  </a>
                </span>
              </center>
            </div>
          </Col>
          <Col xs="1">
            <div
              style={{
                borderLeft: `thick solid ${colors.platinum}`,
                height: "85%",
                position: "absolute",
                left: "50%",
                top: "12%",
              }}
            ></div>
          </Col>
          <Col xs="6" md="5" lg="4">
            <img
              height="100%"
              width="100%"
              src={logo_url}
              alt="SAS prolly expired!"
            ></img>
          </Col>
        </Row>
        <Row>
          <hr
            style={{
              color: colors.platinum,
              backgroundColor: colors.platinum,
              height: 3,
              width: "90%",
              borderColor: colors.platinum,
            }}
          />
        </Row>
      </Container>
    </>
  );
}

export default Header;
